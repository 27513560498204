import { TutuModelVideo } from '@jconradi/tutu-porn-components/services/api/tutu-models/tutu-models';
import { TutuMediaGridItem } from './TutuMediaGridItem';

export interface TutuMediaGridProps {
    tutuVideos: TutuModelVideo[];
}
export const TutuMediaGrid = ({ tutuVideos }: TutuMediaGridProps) => {
    return (
        <div className="lg:ml-4 grid grid-cols-2 min-[480px]:grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-2 flex-1 w-full">
            {tutuVideos.map(t => {
                return (
                    <TutuMediaGridItem autoPlay={false} tutuVideo={t} key={t.slug} />
                )
            })
            }
        </div>
    )
}