import { clsx } from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import React from 'react';
import Star from '../../../public/images/icons/star.svg';

const BuySpotStar = () => <Star className="fill-yellow-400 animate-bounce group-hover:animate-none stroke-outline w-6 h-6 transition group-hover:fill-yellow-500 group-hover:scale-110" />

export const BuySpot = ({ className }: any) => {
    const { t } = useTranslation('common');

    return (
      <Link href="/advertise"
        className={clsx(className,
          `flex flex-col space-y-2 items-center justify-center p-4 group hover:border-primary transition-colors
          bordered border-dashed rounded-md border-outline bg-base-300 border-4`)}>
        <>
          <h1 className="group-hover:underline font-semibold flex flex-row mx-4">
            <BuySpotStar />
            <span>{t('ads.tutu.title')}</span>
            <BuySpotStar />
          </h1>
          <p className='text-xs font-thin'>{t('ads.tutu.subtitle')}</p>
        </>
      </Link>
    )
}