import {
    useQuery,
  } from '@tanstack/react-query'
import { getGallery } from '@jconradi/tutu-porn-api-client/src/gallerys';

export const useGallery = (galleryId: string) => {
    const query = useQuery({
        queryKey: ['gallery', galleryId],
        queryFn: () => getGallery(galleryId)
    });

    return query;
}