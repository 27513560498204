import { UnderlinedButton } from "../Buttons/UnderlinedButton";
import ArrowRight from "../../public/images/icons/arrow_right.svg";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

export interface SeeAllProps {
    text: string;
    icon: any;
    href: string;
}
export const SeeAll = ({ text, icon, href }: SeeAllProps) => {
    const { t } = useTranslation('common');

    return (
        <div className="w-full flex justify-between my-4">
            <div className="flex flex-row gap-x-2 flex-wrap items-center">
                {icon}
                <h5 className="text-[0.875rem] sm:text-xl font-bold leading-[1.5rem] whitespace-normal hover:text-primary">
                    <Link href={href}>{text}</Link>
                </h5>
            </div>

            <UnderlinedButton renderContainer={(props) => <Link {...props} />} href={href}><div className="flex flex-row gap-x-1 items-center justify-center font-bold">
                <span>{t('seeAll')}</span>
                <ArrowRight className="w-8 h-8 text-white stroke-[3px]" />
            </div></UnderlinedButton>
        </div>
    )
}