
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { ReactElement } from 'react'
import { SeeAll } from '@jconradi/tutu-porn-components/components/Home/SeeAll'
import TrendingIcon from '@jconradi/tutu-porn-components/public/images/icons/flash.svg'
import FireIcon from '@jconradi/tutu-porn-components/public/images/icons/fire.svg'
import StarIcon from '@jconradi/tutu-porn-components/public/images/icons/star.svg'
import TrendingTagsIcon from '@jconradi/tutu-porn-components/public/images/icons/trending.svg'

import { MediaSearchOrder, MediaSearchQuery, MediaType, searchMedia } from '@jconradi/tutu-porn-api-client/src/media'
import { dehydrate, QueryClient } from '@tanstack/react-query'
import { MediaGrid } from '@jconradi/tutu-porn-components/components/Media/MediaGrid/MediaGrid'
import { getTrendingTags } from '@jconradi/tutu-porn-api-client/src/tags'
import { useTrendingTags } from '@jconradi/tutu-porn-components/hooks/useTrendingTags'
import { Spinner } from '@jconradi/tutu-porn-components/components/Spinner/Spinner'
import { RoundedLinkButton } from '@jconradi/tutu-porn-components/components/Buttons/LinkButton'
import useTranslation from 'next-translate/useTranslation'
import { BuySpot } from '@jconradi/tutu-porn-components/components/Ads/Tutu/BuySpot'
import Link from 'next/link'
import { getFeaturedTutuVideos, getTutuModelsUrl, TutuModelVideo } from '@jconradi/tutu-porn-components/services/api/tutu-models/tutu-models'
import { TutuMediaGrid } from '@jconradi/tutu-porn-components/components/Ads/TutuModel/TutuMediaGrid'
import { shuffleArray } from '@jconradi/tutu-porn-components/util/random'
import { BannerAd } from '@jconradi/tutu-porn-components/components/Ads/Exoclick/BannerAd'
import { getLayout } from '../components/Layout/Layout'

const MediaPerFeed = 23;

const TrendingQueryArgs: MediaSearchQuery = {
  order: MediaSearchOrder.Trending,
  count: MediaPerFeed
}

const HottestImagesQueryArgs: MediaSearchQuery = {
  order: MediaSearchOrder.Trending,
  type: MediaType.Photo,
  count: MediaPerFeed
}

const NewestMedia: MediaSearchQuery = {
  order: MediaSearchOrder.Latest,
  count: 12
}

const WeeklyTop: MediaSearchQuery = {
  order: MediaSearchOrder.WeeklyTop,
  count: 12
}

const MonthlyTop: MediaSearchQuery = {
  order: MediaSearchOrder.MonthlyTop,
  count: 12
}

const HorizontalVideos: MediaSearchQuery = {
  order: MediaSearchOrder.MonthlyTop,
  count: 12
}

const VerticalVideos: MediaSearchQuery = {
  order: MediaSearchOrder.MonthlyTop,
  count: 12
}

const FeaturedCategories = ['Asian', 'Chinese', 'Teen', 'Russian', 'Amateur', 'Feet', 'Cuckold'];
const featuredCategoryQuery = (cat: string) => {
  return {
    order: MediaSearchOrder.Trending,
    count: 12,
    tags: [cat.toLowerCase()]
}
}

export interface HomeProps {
  tutuVideos: TutuModelVideo[];
}
export default function Home({ tutuVideos  }: HomeProps) {
  const { t, lang } = useTranslation('common')

  const tags = useTrendingTags();

  return (
    <section className="flex flex-col w-full">
      <BuySpot className="mb-2" />

      <SeeAll text={t('pages.index.trending')} href="/browse?order=trending" icon={<TrendingIcon />} />
      <MediaGrid loading={true} query={TrendingQueryArgs} />

      <BuySpot className="mt-6" />
      
      <SeeAll text={t('tutuModels')} href={getTutuModelsUrl("/")} icon={<StarIcon className="w-8 h-8 fill-yellow-400 stroke-outline mx-2" />} />
      <TutuMediaGrid tutuVideos={tutuVideos} />

      <BuySpot className="mt-6"  />
      <SeeAll text={t('mediaSearchOrder.latest')} href="/browse?order=latest" icon={<FireIcon />} />
      <MediaGrid loading={true} query={NewestMedia} />

      <BuySpot className="mt-6"  />
      <SeeAll text={t('trendingTags')} href="/tags" icon={<TrendingTagsIcon />} />
      {tags.isLoading && <Spinner className="mx-auto" />}
      <div className="flex flex-row flex-wrap gap-[8px] justify-start max-h-[calc(336px+4rem)] overflow-hidden">
          {tags.data?.slice(0, 100).map(tag => {
              return (
                  <RoundedLinkButton key={tag.name} tag={tag.name} className='px-[16px] py-[4px] text-base leading-[24px]'>
                      <p className='font-thin text-sm leading-[10px]'>{`${tag.mediaCount} Uploads`}</p>
                  </RoundedLinkButton>
              )
            })
          }
        </div>

      {FeaturedCategories.map(cat => {
        return (
          <div key={cat.toLowerCase()}>
                <BuySpot className="mt-6"  />
                <SeeAll text={`#${cat.toLocaleLowerCase()}`} href={`/browse?order=trending&tags=${cat}`} icon={<FireIcon />} />
                <MediaGrid loading={true} query={featuredCategoryQuery(cat)} />
          </div>
        )
      })}

      {/* <SeeAll text={t('pages.index.hottestImages')} href="/browse?order=trending&type=photo" icon={<FireIcon />} />
      <MediaGrid loading={true} query={HottestImagesQueryArgs} /> */}

      {/* <SeeAll text={t('mediaSearchOrder.weeklyTop')} href="/browse?order=weeklyTop" icon={<FireIcon />} />
      <MediaGrid loading={true} query={WeeklyTop} />

      <SeeAll text={t('mediaSearchOrder.monthlyTop')} href="/browse?order=monthlyTop" icon={<FireIcon />} />
      <MediaGrid loading={true} query={MonthlyTop} />

      <SeeAll text={t('mediaFilters.horizontal')} href="/browse?order=trending&horizontal=1" icon={<FireIcon />} />
      <MediaGrid loading={true} query={HorizontalVideos} />

      <SeeAll text={t('mediaFilters.vertical')} href="/browse?order=trending&vertical=1" icon={<FireIcon />} />
      <MediaGrid loading={true} query={VerticalVideos} />                 */}

      <Link href="/browse?order=latest" className="btn btn-secondary my-4 h-16">
        {t('pages.index.more')}
      </Link>
    </section>
  )
}

Home.getLayout = (page: ReactElement) => getLayout(page)

 async function _getStaticProps({ locale }: any) {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(['media', TrendingQueryArgs], async () => {
    const resp = await searchMedia(TrendingQueryArgs);
    return resp.data;
  });

  // await queryClient.prefetchQuery(['media', HottestImagesQueryArgs], async () => {
  //   const resp = await searchMedia(HottestImagesQueryArgs);
  //   return resp.data;
  // });

  for (const cat of FeaturedCategories) {
    await queryClient.prefetchQuery(['media', featuredCategoryQuery(cat)], async () => {
      const resp = await searchMedia(featuredCategoryQuery(cat));
      return resp.data;
    });
  }

  await queryClient.prefetchQuery(['media', NewestMedia], async () => {
    const resp = await searchMedia(NewestMedia);
    return resp.data;
  });

  // await queryClient.prefetchQuery(['media', WeeklyTop], async () => {
  //   const resp = await searchMedia(WeeklyTop);
  //   return resp.data;
  // });

  // await queryClient.prefetchQuery(['media', MonthlyTop], async () => {
  //   const resp = await searchMedia(MonthlyTop);
  //   return resp.data;
  // });

  // await queryClient.prefetchQuery(['media', VerticalVideos], async () => {
  //   const resp = await searchMedia(VerticalVideos);
  //   return resp.data;
  // });

  // await queryClient.prefetchQuery(['media', HorizontalVideos], async () => {
  //   const resp = await searchMedia(HorizontalVideos);
  //   return resp.data;
  // });

  await queryClient.prefetchQuery(['tags', 'trending'], async () => {
    const resp = await getTrendingTags();
    return resp.data;
  });

  const featuredVideos = await getFeaturedTutuVideos();
  const shuffledFeaturedVideos = shuffleArray(featuredVideos).slice(0, 6);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      tutuVideos: shuffledFeaturedVideos
    },
    revalidate: 60
  }
}

// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  