import Image, { ImageLoader, ImageLoaderProps, ImageProps } from 'next/image';
import { useCallback, useState } from 'react';
import cls from 'clsx';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { MediaError } from '../Errors/MediaError/MediaError';


const queryString = (params: any) => Object.keys(params).filter(key => params[key] !== undefined).map(key => key + '=' + params[key]).join('&');


export const BunnyCdnImage = ({ ...props }: any) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ error, setError ] = useState(false);

    const loader = useCallback<ImageLoader>(({width, quality, src}: ImageLoaderProps) => {
        return `${src}?${queryString({
            width,
            quality,
            ...props.query || {}
        })}`;
    }, [props]);

    return (
        <>
        <Image priority alt="" onLoad={() => setLoaded(true)} onLoadStart={() => setLoaded(false)} onError={() => setError(true)}  {...props} className={cls(props.className, {
            ['hidden']: !loaded
        })} loader={loader}  />
        {!loaded && !error && <Skeleton className='absolute inset-0 z-0' />}
        {error &&  <MediaError />}

        </>
    )
}